import { TimeUnit } from '@/components/Countdown/styled';
import { CountdownRenderProps } from 'react-countdown';

export const renderer = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
}: CountdownRenderProps) => {
  if (completed) {
    return null;
  }

  return (
    <>
      <TimeUnit>
        {days}
        <span>days</span>
      </TimeUnit>
      <TimeUnit>
        {hours}
        <span>hours</span>
      </TimeUnit>
      <TimeUnit>
        {minutes}
        <span>minutes</span>
      </TimeUnit>
      <TimeUnit>
        {seconds}
        <span>seconds</span>
      </TimeUnit>
    </>
  );
};
