import React, { ReactElement } from 'react';
import Link from 'next/link';

import { Parser, ProcessNodeDefinitions } from 'html-to-react';

type HtmlNode = Element & { name?: string; attribs: { [key: string]: string } };

export function FormattedDescription({ description }: { description: string }) {
  const processingInstructions = [
    {
      // Custom <a /> processing
      shouldProcessNode: (node: HtmlNode) => {
        return node.name === 'a' && node.attribs.href.startsWith('/');
      },
      processNode: (
        node: HtmlNode,
        children: ReactElement[],
        index: number
      ) => {
        return (
          <Link
            key={index}
            href={node.attribs.href}
            className={node.attribs.class}
          >
            {children[0]}
          </Link>
        );
      },
    },
    {
      // Anything else
      shouldProcessNode: () => {
        return true;
      },
      // @ts-expect-error
      processNode: new ProcessNodeDefinitions(React).processDefaultNode,
    },
  ];

  // @ts-expect-error
  const elements = new Parser().parseWithInstructions(
    description,
    () => {
      return true;
    },
    processingInstructions
  );

  return elements;
}
