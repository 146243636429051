import styled from 'styled-components';

export const Text = styled.div`
  width: 100%;
`;

export const Container = styled.section`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  justify-content: center;
  background-color: #111f2d;
  color: #fff;

  ${Text} > p {
    max-width: 660px;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  a {
    color: #f1e5cd;
  }

  @media (max-width: 768px) {
    padding: 24px 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  justify-content: center;
`;

export const TimeUnit = styled.span`
  user-select: none;
  background-color: rgba(0, 0, 0, 0.25);
  color: #c0ad86;
  font-size: 96px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 196px;

  > span {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
  }

  @media (max-width: 992px) {
    font-size: 64px;
    padding: 16px;
    min-width: 130px;

    > span {
      font-size: 14px;
      font-weight: 500;
    }
  }

  @media (max-width: 600px) {
    font-size: 24px;
    min-width: 80px;

    > span {
      font-size: 12px;
    }
  }

  @media (max-width: 480px) {
    min-width: 0;
  }
`;
