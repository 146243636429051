import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

const NextHead = ({ meta }: any) => {
  const router = useRouter();
  return (
    <Head>
      <title>{meta.title}</title>
      <link
        rel="canonical"
        href={
          (process.env.NEXT_PUBLIC_CANONICAL_URL ?? '') +
          (meta?.canonical ?? router.asPath)
        }
      />
      <meta name="description" content={meta.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta property="og:title" content={meta.title} key="og:title" />
      <meta
        property="og:description"
        content={meta.description}
        key="og:description"
      />
      <meta property="keywords" content={meta.keywords} key="keywords" />
    </Head>
  );
};

export default NextHead;
