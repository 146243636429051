import Countdown from 'react-countdown';
import { useMounted } from '@/hooks/useMounted';
import { Container, Wrapper, Text } from '@/components/Countdown/styled';
import { renderer } from '@/components/Countdown/renderer';
import { FormattedDescription } from '@/components/Countdown/FormattedDescription';

export type CountDownSettings = {
  id: number;
  status: 'published' | 'draft' | 'deleted';
  owner: number;
  created_on: Date;
  description: string;
  start_date: Date;
  end_date: Date;
};

type CountDownProps = {
  settings: CountDownSettings;
};
export function CountDownTimer({ settings }: CountDownProps) {
  const { start_date, end_date, description, status } = settings;
  const isMounted = useMounted();

  const currentDate = new Date();
  const startDate = new Date(start_date);
  const endDate = new Date(end_date);

  if (!isMounted || status !== 'published') {
    return null;
  }

  if (startDate && (currentDate < startDate || currentDate > endDate)) {
    return null;
  }

  return (
    <Container>
      <Wrapper>
        <Countdown date={endDate} renderer={renderer} />
        <Text>
          <FormattedDescription description={description} />
        </Text>
      </Wrapper>
    </Container>
  );
}
